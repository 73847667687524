export const isVisible = function (
  selectedTicketTypes,
  entity,
  purchaseHistory = []
) {
  if (!entity?.purchaseRestrictions) {
    return true;
  }

  for (const restriction of entity.purchaseRestrictions) {
    if (restriction.isAlwaysVisible) {
      return true;
    }
  }

  return isAvailableForSelection(selectedTicketTypes, entity, purchaseHistory);
};

export const isAvailableForSelection = function (
  selectedTicketTypes,
  entity,
  purchaseHistory = []
) {
  if (!entity?.purchaseRestrictions) {
    return true;
  }

  if (!entity.purchaseRestrictions.length) {
    return true;
  }

  for (const restriction of entity.purchaseRestrictions) {
    if (hasRequired(selectedTicketTypes, restriction, purchaseHistory)) {
      return true;
    }
  }

  return false;
};

const hasRequired = function (
  selectedEntities,
  restriction,
  purchaseHistory = []
) {
  for (const requiredEntity of restriction.requiredEntities) {
    const requiredTicketType = selectedEntities.find(
      (tt) => tt.id === requiredEntity.requiredId
    );

    if (
      !requiredTicketType ||
      requiredTicketType.quantity < restriction.quantity
    ) {
      const requiredTicketType = purchaseHistory.find(
        (tt) => tt.ticketType.id === requiredEntity.requiredId
      );

      if (
        !requiredTicketType ||
        requiredTicketType.total < restriction.quantity
      ) {
        return false;
      }
    }
  }

  return true;
};
