import sortBy from "lodash/sortBy";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo } from "react";
import useSWR from "swr";
import { BaseContext } from "~context";
import { LineItemType, OrderType } from "~graphql/sdk";
import { useAccount } from "~hooks/useAccount";
import * as fetchers from "../../graphql/fetchers";
import { usePricing } from "./usePricing";
import { useReferralCode } from "~hooks/useReferralCode";
import { CreateOrderFn } from "./useReservation";
import { useCreateOrder } from "./useCreateOrder";

export interface Props {
  type: "event" | "membership";
  id: string;
  releaseId?: string;
  slug?: string;
  activeReleaseOnly?: boolean;
}

export const useNonSeatedReservation = ({
  type,
  id,
  releaseId,
  slug,
  activeReleaseOnly,
}: Props) => {
  const router = useRouter();
  const { organization } = useContext(BaseContext);
  const { isAdmin } = useAccount();
  const { referralIdOrCode } = useReferralCode();
  const { createOrderAndWaitForCompletion } = useCreateOrder();

  const { error: eventError, data: event } = useSWR(
    type === "event" && id ? ["event", organization?.id, id] : null,
    fetchers.getEvent
  );

  const { error: membershipError, data: membership } = useSWR(
    type === "membership" ? ["membership", organization?.id, id] : null,
    fetchers.getMembership
  );

  //memberships dont have releases and shouldnt be firing
  const { data: release, error: releaseError } = useSWR(
    type === "membership" || !id
      ? null
      : [
          "release",
          organization?.id,
          id,
          releaseId,
          slug,
          activeReleaseOnly ?? slug ? true : false,
        ],
    fetchers.getRelease
  );

  const isChangingSeats = !!router.query.ownedTickets;

  const { data: ownedTicketsData, error: ownedTicketsError } = useSWR(
    organization?.id && router.query.ownedTickets
      ? ["tickets", organization?.id, id]
      : null,
    fetchers.getMyTickets
  );

  const {
    data: ownedMembershipTicketsData,
    error: ownedMembershipTicketsError,
  } = useSWR(
    organization?.id && router.query.ownedTickets
      ? ["membershipTickets", organization?.id]
      : null,
    fetchers.getMyMembershipTickets
  );

  useEffect(() => {
    if (router.query.posId) {
      if (localStorage.getItem("posId")) {
        localStorage.removeItem("posId");
        localStorage.setItem("posId", router.query.posId as string);
      } else {
        localStorage.setItem("posId", router.query.posId as string);
      }
    }
  }, [router.query.posId]);

  const releasedZones = useMemo(
    () =>
      type === "event" ? release?.releaseZones : membership?.membershipZones,
    [type, membership?.membershipZones, release?.releaseZones]
  );

  const nonSeatedFilteredZone = useMemo(() => {
    return releasedZones?.map((releasedZone) => releasedZone.zone);
  }, [releasedZones]);

  const { pricedZones } = usePricing({
    filteredZones: nonSeatedFilteredZone,
    releasedZones,
    type,
    isAdmin,
  });

  const sortedZones = sortBy(pricedZones, (zone) => zone.displayOrder ?? 0);

  const handleGAOrderCreation: CreateOrderFn = async (
    items,
    addonItems,
    multiBuyId,
    errorCallback
  ) => {
    const orderType = Object.values(OrderType).includes(
      router.query.type as OrderType
    )
      ? (router.query.type as OrderType)
      : undefined;

    const releasePasswordId =
      sessionStorage.getItem("releasePasswordId") || undefined;

    const addonLineItems =
      addonItems?.map((addon) => ({
        quantity: addon.quantity,
        type: LineItemType.Addon,
        addonId: addon.id,
      })) || [];

    const input = {
      lineItems: [
        ...items.map((ticket) => ({
          quantity: ticket.quantity,
          ...(type === "event" && {
            ticketTypeId: ticket.id,
            type: LineItemType.Ticket,
          }),
          ...(type === "membership" && {
            membershipTypeId: ticket.id,
            type: LineItemType.Membership,
          }),
          seatZone: ticket.zoneId,
        })),
        ...addonLineItems,
      ],
      multiBuyId,
      orderType,
      ...(type === "event"
        ? {
            releaseId: release?.id,
          }
        : type === "membership"
        ? {
            membershipId: release?.id ?? membership?.id,
          }
        : {
            releaseId: release?.id,
          }),
      ...(releasePasswordId && { releasePasswordId }),
      ...referralIdOrCode,
    };

    return await createOrderAndWaitForCompletion(input, errorCallback);
  };

  return {
    error:
      type === "event"
        ? eventError || ownedTicketsError || releaseError
        : membershipError || ownedMembershipTicketsError,
    event,
    handleGAOrderCreation,
    membership,
    isLoading:
      type === "event"
        ? !event &&
          !eventError &&
          !release &&
          !releaseError &&
          (!isChangingSeats ||
            (type === "event"
              ? ownedTicketsData && !ownedTicketsError
              : ownedMembershipTicketsData && !ownedMembershipTicketsError))
        : !membership && !membershipError,
    release,
    selectableZones: sortedZones,
  };
};
